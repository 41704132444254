@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }
}

@layer utilities {
    .pb-safe {
        padding-bottom: env(safe-area-inset-bottom);
    }

    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}
